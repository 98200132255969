import React from 'react';
import './WhatsappButton.css'; 

const WhatsappButton = () => {
  return (
    <a
      href="https://wa.me/5524981313448?text=Olá! Tenho interesse em participar do Conceito Day"
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
};

export default WhatsappButton;
