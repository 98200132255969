
import './App.css';
import imgPosicionamento from './img/posicionamento.png';
import imgRedeSociais from './img/redes-sociais.png';
import imgEstrategia from './img/estrategia.png';
import imgLogoConceito from './img/logo_conceito.png';
import imgVl from './img/valor.png';
import imgSelo from './img/selo_conceito.png';
import InputMask from 'react-input-mask';
import WhatsappButton from './components/whatsappbutton/WhatsappButton';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';
import { useState } from 'react';
function App() {
  const [nome, setNome] = useState('');
  const [sobre, setSobre] = useState('');
  const [especilidade, setEspecilidade] = useState('');
  const [instagram, setInstagram] = useState('');
  const [Whatsapp,setWhatsapp]=useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const scrollToBottom = () => {
   
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };
 
  const sendEmail = (e)=>{
    e.preventDefault();
    if(sobre !==""){
      setSobre('');
      return;
    }
    if(instagram ==="" && Whatsapp ===""){
      Swal.fire({
        title: "OPS!!",
        text: 'Por favor! Informe um contato!',
        icon: 'error'
      });
      return;
    }
    if(nome ===""){
      Swal.fire({
        title: "OPS!!",
        text: 'Por favor! Informe um contato!',
        icon: 'error'
      });
      return; 
  }
  setIsDisabled(true);
  const parms={
    "from_name":nome,
    "from_especialidade":especilidade,
    "from_instagram":instagram,
    "from_whatsapp":Whatsapp
  }
    emailjs.send("service_ero6nfn","template_ala3yww",parms,"40wtHx21_WaGoV5QI")
    .then((response)=>{
      let texto = `<h1>Olá ${nome}</h1>,
  
      <p> Agradecemos imensamente sua inscrição para o nosso evento exclusivo <storng>CONCEITO DAY - OS SEGREDOS DE UM NEGÓCIO LUCRATIVO! </strong></p>
       
       <p>Estamos muito empolgados em tê-lo(a) conosco para este dia especial, onde compartilharemos insights valiosos e estratégias práticas para transformar seu negócio de beleza em uma verdadeira máquina de lucros.</p>
       
       <p>Fique atento(a)! A Hair Business Carol Santos entrará em contato pessoalmente com você em breve para liberar o seu convite e fornecer todos os detalhes necessários para que você possa aproveitar ao máximo esta experiência única.</p>
       
      <p> Prepare-se para um evento transformador e cheio de oportunidades.</p> 
      <p>Estamos ansiosos para vê-lo(a) lá!</p>`
      Swal.fire({
        title: "Sucesso",
        html: texto,
        icon: 'success'
      });
      setNome('');
      setEspecilidade('');
      setInstagram('');
      setWhatsapp('');
      setIsDisabled(false);
    },(err)=>{
      Swal.fire({
        title: "OPS!!",
        text: 'Algo deu errado! Por favor tente mais tarde ou entre encontado com o nosso Whatsapp!',
        icon: 'error'
      });
      setIsDisabled(false);
    });
   
  }
  return (

      <div className="row box-primary">
      <div className='container'>
        <div className='row justify-content-center'>
          <section className='col-12 text-center' id="section-primary">
            <div className='row justify-content-center'>
              <div className='col-12 col-sm-10 col-md-6 '>
                <h1 className='text-center'>CONCEITO DAY</h1>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-12 col-sm-8'>
                <p className='text-center'>Um dia para você descobrir</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-12 col-sm-10'>
                <h2 className='text-center'>OS SEGREDOS DE UM NEGÓCIO LUCRATIVO</h2>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-10'>
                <h3 className='text-center'>23 Jun | HOTEL VICTORY BUSINESS, JUIZ DE FORA </h3>
              </div>
            </div>
            <div className='row justify-content-center mt-5 mb-5'>
              <div className='col-12 col-sm-6'>
                <button className="btn btn-padrao-lg animate__animated animate__pulse animate__infinite infinite" onClick={scrollToBottom}>Garantir meu lugar!</button>
              </div>
            </div>
          </section>
          
          <section className='col-12 mt-5' id="section-second">
            <div className='row justify-content-center'>
              <div className='col-12 col-md-6'>
                <h2 className='text-center'>O que vai aprender?</h2>
              </div>
            </div>
            <div className='row justify-content-center mt-5'>
              <div className='col-12 col-md-3 text-center'>
                <img src={imgPosicionamento} className="img-fluid" alt="Posicionamento que vende mais"/>
                <h3 className='mt-5'>Posicionamento</h3>
                <span>Posicionamento que vende mais</span>
              </div>
              <div className='col-12 col-md-3 text-center'>
                <img src={imgRedeSociais} className="img-fluid" alt="Rede sociais para atrair negócios"/>
                <h3 className='mt-5'>Rede Sociais</h3>
                <span>Rede sociais para atrair negócios</span>
              </div>
              <div className='col-12 col-md-3 text-center'>
                <img src={imgEstrategia} className="img-fluid" alt="Estratégias para escalar resultados financeiros"/>
                <h3 className='mt-5'>Estratégias</h3>
                <span>Estratégias para escalar resultados financeiros</span>
              </div>
            </div>
            <div className='row justify-content-center' id="box-second">
              <div className='col-12 col-md-6 text-center'>
                <img src={imgLogoConceito} className="img-fluid" alt="Conceito Beauty Club"/>
              </div>
              <div className='col-12 col-md-8'>
                <div className='row justify-content-center'>
                  <div className='col-12 col-md-6 text-center'>
                    <h4>PARA QUEM É <br /><span>CONCEITO DAY?</span></h4>
                  </div>
                </div>
                <div className='row justify-content-center mt-5'>
                  <div className='col-12 col-md-8'>
                    <p>PROFISSIONAIS DA ÁREA DA BELEZA QUE DESEJAM SER PRÓSPEROS E BEM SUCEDIDOS.</p>
                  </div>
                  <div className='col-12 col-md-8'>
                    <p>PROFISSIONAIS DA ÁREA DA BELEZA QUE QUEREM FAZER A DIFERENÇA NO MERCADO SENDO VALORIZADOS E RECONHECIDOS.</p>
                  </div>
                  <div className='col-12 col-md-8'>
                    <p>PROFISSIONAIS DA ÁREA DA BELEZA QUE QUEREM ESTRUTURAR SEU NEGÓCIO.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='row justify-content-center mt-5' id='section-third'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-6 mt-5'>
                  <div className='row justify-content-center'>
                    <div className='col-12 col-md-6 text-center'>
                      <h4>QUAL VALOR DO<br /><span>INVESTIMENTO?</span></h4>
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-12 text-center vl'>
                      <img src={imgVl} className="img-fluid" alt="R$97,00"/>
                      <small>R$</small>
                    </div>
                  </div>
                  <div className='row mt-5 justify-content-center'>
                    <div className='col-12 col-md-8'>
                      <p>O valor do convite está incluso COFFE BREAK e certificado de participação.</p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-6 mt-5'>
                  <div className='row justify-content-center'>
                    <div className='col-12 col-md-6 text-center'>
                      <img src={imgSelo} className="img-fluid" alt="Selo Conceito"/>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-6 mt-5'>
                <div className='row justify-content-center'>
                    <div className='col-12  text-center'>
                      <h4>PREENCHAR O FORMULÁRIO</h4>
                       <p>Entraremos em contato</p>
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-10'>
                        <form id='formCad' onSubmit={sendEmail}>
                          <div class="mb-3">
                            <label for="nome" class="form-label ">Nome</label>
                            <input type="text" class="form-control input-custom" id="nome" name='nome'  onChange={(e) => setNome(e.target.value)} value={nome}/>
                            <input type="text" class="form-control input-custom" id="sobre" name='sobre'  onChange={(e) => setSobre(e.target.value)} value={sobre}/>
                          </div>
                          <div class="mb-3">
                            <label for="especilidade" class="form-label ">Especilidade <small>(Ex. Cabeleireira)</small></label>
                            <input type="text" class="form-control input-custom" id="especilidade" name='especilidade'  onChange={(e) => setEspecilidade(e.target.value)} value={especilidade}/>
                          </div>
                          <div class="mb-3">
                            <label for="instagram" class="form-label ">Instagram</label>
                            <input type="text" class="form-control input-custom" id="instagram" name='instagram'  onChange={(e) => setInstagram(e.target.value)} value={instagram}/>
                          </div>
                          <div class="mb-3">
                            <label for="whatsapp" class="form-label ">Whatsapp</label>
                            <InputMask mask="(99) 9999-9999" maskChar={null} type="text" class="form-control input-custom" id="Whatsapp" name='Whatsapp'  onChange={(e) => setWhatsapp(e.target.value)} value={Whatsapp}/>
                          </div>
                          <div class="mb-3">
                          <button type="submit" class="btn btn-padrao-lg"  disabled={isDisabled}>Garantir meu lugar!</button>
                          </div>
                          
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <WhatsappButton />
        </div>
      </div>
    </div>
      
   
             
           

  );
}

export default App;
